import clsx from 'clsx';
import * as styles from './loadingIndicator.module.css';

interface LoadingIndicatorProps {
    size?: 'tiny' | 'medium' | 'large',
}

const LoadingIndicator = ({size = 'large'}: LoadingIndicatorProps) => (
    <div className={styles.loadingWrapper}>
        <div className={clsx(styles.loader, styles[size])}/>
    </div>
);

export default LoadingIndicator;
