import {Link, navigate} from 'gatsby';
import clsx from 'clsx';
import {PropsWithChildren} from 'react';
import * as styles from './customLink.module.css';
import * as stylesTabs from '../tabs/tabs.module.css';
import * as stylesMenu from '../../layout/menu/menu.module.css';

interface ICustomLink {
    to: string,
    intercept?: {
        status: boolean,
        action: () => void,
        setOnConfirm: (confirm: () => () => void) => void,
    },
    className?: string,
    isTab?: boolean,
    isMenu?: boolean,
}

interface IIsActiveTab {
    isCurrent: boolean,
    isTab: boolean,
    isMenu: boolean,
    className: string,
    currentLocation: string,
    href: string,
}

const isActiveTab = ({
    isCurrent,
    isTab,
    isMenu,
    className,
    currentLocation,
    href,
}: IIsActiveTab) => {
    const classNames = [styles.link, className];

    if (isCurrent && isTab) {
        classNames.push(stylesTabs.tabActive);
    }

    const regex = new RegExp(`${href}/`, 'i');

    if (isMenu && (regex.test(currentLocation) || currentLocation === href)) {
        classNames.push(stylesMenu.active);
    }

    return ({className: clsx(...classNames)});
};

const CustomLink = ({
    children,
    to,
    intercept,
    className,
    isTab,
    isMenu,
}: PropsWithChildren<ICustomLink>) => (
    <Link
        to={to}
        getProps={({
            isCurrent,
            location,
            href,
        }) => isActiveTab({
            isCurrent,
            isTab: isTab === true,
            isMenu: isMenu === true,
            className: className || '',
            currentLocation: location.pathname,
            href,
        })}
        onClick={e => {
            e.preventDefault();

            if (intercept && intercept.status) {
                intercept.setOnConfirm(() => () => {
                    navigate(to);
                });
                intercept.action();

                return;
            }

            navigate(to);
        }}
    >
        {children}
    </Link>
);

export default CustomLink;
