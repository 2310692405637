// extracted by mini-css-extract-plugin
export var email = "login-module--email--iM32k";
export var envelopBottom = "login-module--envelopBottom--0GqYk";
export var envelopTop = "login-module--envelopTop--t2Gk6";
export var envelopWrapper = "login-module--envelopWrapper--NKk5j";
export var error = "login-module--error--4cEWJ";
export var link = "login-module--link--oFyqv";
export var list = "login-module--list--sHNRc";
export var listToEnvelop = "login-module--list-to-envelop--ZaKRv";
export var loader = "login-module--loader--jdjjP";
export var login = "login-module--login--48n6U";
export var logo = "login-module--logo--kt0dx";
export var logoToList = "login-module--logo-to-list--iBMM5";
export var root = "login-module--root--jrydN";
export var sadClown = "login-module--sadClown--2Vwr0";
export var spin = "login-module--spin--oR8b4";
export var success = "login-module--success--9mfa7";
export var text = "login-module--text--QIi0G";
export var title = "login-module--title--GZDOm";
export var trouble = "login-module--trouble--czatC";