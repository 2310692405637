import {useEffect, useState} from 'react';
import clsx from 'clsx';
import {navigate} from 'gatsby';
import {Helmet} from 'react-helmet';
import {CancelIcon, CheckIcon} from '../../../components/core/icons';
import {Button} from '../../../components/core';
// import {accountVar} from '../../../helpers/graphql/variables';
import * as styles from '../login.module.css';

const Magiclink = ({token}: {token: string}) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!token) {
            return;
        }

        const getToken = () => {
            fetch(`${process.env.GATSBY_API_BASE_URL}/auth/magiclink/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({token: token || ''}),
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }

                    return Promise.reject(response);
                })
                .then(() => {
                    // accountVar(response.data);

                    setTimeout(() => {
                        setMessage('Magic link validated. You\'ll be redirected to IX.');
                    }, 1000);

                    setTimeout(() => {
                        navigate('/');
                    }, 3000);
                })
                .catch(err => {
                    if (!err.json) {
                        setError('There was an error while validating magic link');

                        return;
                    }

                    err
                        .json()
                        .then(({error: data}: {error: any}) => {
                            const errorMessage = data.message ? `${data.message}: ${data.errorMessage}` : data;

                            setTimeout(() => {
                                setError(errorMessage);
                            }, 1000);
                        });
                });
        };

        getToken();
    }, [token]);

    return (
        <main className={styles.root}>
            <Helmet>
                <title>Magic link | IX</title>
            </Helmet>
            <div className={styles.login}>
                <div
                    className={clsx(
                        styles.loader,
                        message && styles.success,
                        error && styles.error,
                    )}
                >
                    {message && (<CheckIcon/>)}
                    {error && (<CancelIcon/>)}
                </div>
                <p
                    className={clsx(
                        styles.text,
                        error && styles.error,
                    )}
                >
                    {message || error || 'Validating magic link'}
                </p>
                {error && (
                    <Button
                        text="Login again"
                        onClick={() => navigate('/account/login')}
                    />
                )}
                {/* <a href="#" className={styles.trouble}>Problem with login</a> */}
            </div>
        </main>
    );
};

export default Magiclink;
